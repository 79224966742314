












































import { Component, Prop, Vue } from 'vue-property-decorator';
import Taxi from '@/models/Taxi';
import { namespace } from 'vuex-class';
import { applicationStoreGetter } from '@/store/application.store';
import Company from '@/models/Company';
import { taxiStoreActions, taxiStoreMutations } from '@/store/taxi.store';

const TaxiStore = namespace('taxi');
const ApplicationStore = namespace('application');

@Component({
    components: {
        EditTaxiComponent: () => import(
            /* webpackChunkName: "EditTaxiComponent" */
            '@/components/EditTaxi.component.vue')
    }
})
export default class TaxiTableComponent extends Vue {

    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    @TaxiStore.Action(taxiStoreActions.DELETE)
    public deleteTaxiAction!: (payload: { taxi: Taxi }) => Promise<Taxi>;

    @TaxiStore.Action(taxiStoreActions.LIST)
    public loadAllTaxis!: (payload: { company: Company }) => Promise<Taxi[]>;

    @TaxiStore.State('taxi')
    public taxi!: Taxi;

    @TaxiStore.Mutation(taxiStoreMutations.SAVE_TAXI)
    public saveTaxi!: (taxi: Taxi | null) => void;

    @Prop({ default: () => [] })
    public taxis!: Taxi[];

    @Prop({ default: '' })
    public searchValue!: string;

    public showEditDialog: boolean = false;
    public showDeleteDialog: boolean = false;

    public tableHeaders = [{
        text: this.$t('TAXI.TABLE.TAXINO'),
        value: 'taxiNo',
        sortable: true
    }, {
        text: this.$t('TAXI.TABLE.PLATE'),
        value: 'plate',
        sortable: true
    }, {
        text: this.$t('TAXI.TABLE.SEATS'),
        value: 'seats',
        sortable: false
    }, {
        text: this.$t('TAXI.TABLE.TYPE'),
        value: 'type',
        sortable: false
    }, {
        text: this.$t('TAXI.TABLE.CREATION_DATE'),
        value: 'createdAt',
        sortable: false
    }, {
        text: this.$t('TAXI.TABLE.OPTIONS'),
        value: 'options',
        sortable: false,
        align: 'right'
    }];

    public rowSelected(taxi: Taxi) {
        console.log(taxi);
    }

    public editSelected(taxi: Taxi) {
        this.saveTaxi(taxi);
        this.showEditDialog = true;
    }

    public deleteSelected(taxi: Taxi) {
        this.saveTaxi(taxi);
        this.showDeleteDialog = true;
    }

    public async closeDialog(reload: boolean) {
        if (reload) {
            await this.loadAllTaxis({ company: this.selectedCompany });
        }

        this.showEditDialog = false;
        this.saveTaxi(null);
    }

    public deleteConfirmed() {
        this.deleteTaxiAction({ taxi: this.taxi });
        this.showDeleteDialog = false;
    }

    public closeDeleteDialog() {
        this.saveTaxi(null);
        this.showDeleteDialog = false;
    }
}

